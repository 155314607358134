<template>
  <default-layout>
    <div class=" m-4">
      <div v-if="noData" class="flex items-center justify-center">
        <span class="mr-2">Không có dữ liệu</span>
        <base-button
          :disabled="noDataBtnLoading"
          :loading="noDataBtnLoading"
          class="border-16 bg-tertiary text-white px-6 py-2"
          @click="fetchListData"
        >
          Tải lại
        </base-button>
      </div>
      <div v-else>
        <div class=" mb-4">
          <base-input-search v-model="search" class="w-full" />
        </div>
      </div>

      <div class=" h-full">
        <div v-if="loading">
          Loading ...
        </div>
        <div v-else>
          <salary-advance-item
            v-for="(item, index) in list"
            :key="index"
            :a-invest="item"
            @remove="removeItem"
          />
        </div>
      </div>
      <div v-if="noResult">Không tìm thấy kết quả tìm kiếm</div>
    </div>
  </default-layout>
</template>

<script lang="ts">
import { defineComponent, onMounted, ref, watch } from 'vue';
// import { useStore } from 'vuex';
import { useRouter } from 'vue-router';
// import * as Helpers from '@/helpers';
import * as ApiContract from '@/apis/ApiContract';
import ConstRouter from '@/constants/constRouter';
import BaseButton from '@/components/Base/BaseButton.vue';
import SalaryAdvanceItem from '@/components/SalaryAdvance/SalaryAdvanceItem.vue';
import BaseInputSearch from '@/components/Base/BaseInputSearch.vue';
import DefaultLayout from '@/layouts/DefaultLayout.vue';
import { Contract } from '@/models/Contract.ts';
import { addSeconds } from 'date-fns';

export default defineComponent({
  name: 'LenderInvests',
  components: {
    DefaultLayout,
    BaseInputSearch,
    SalaryAdvanceItem,
    BaseButton,
  },
  setup() {
    const router = useRouter();
    // const store = useStore();

    const noDataBtnLoading = ref(false);
    const noData = ref(false);
    const loading = ref(false);
    const search = ref('');
    const list = ref([]);
    const listTemp = ref([]);

    const handleClickItem = () => {
      router.push({
        name: ConstRouter.INTRO_INPUT_NAME.name,
      });
    };
    watch(search, val => {
      console.log('vadasdal', val);
    });

    const fetchListData = () => {
      loading.value = true;
      noDataBtnLoading.value = true;
      ApiContract.listWithManual()
        .then(res => {
          let localList = res.data;
          localList = localList.map((item: Contract) => ({
            ...item,
            expireTime: item.expired
              ? addSeconds(new Date(), Math.floor(Math.random() * 99999))
              : null,
          }));

          // this.setListInvest(list);
          list.value = localList;
          listTemp.value = localList;
          loading.value = false;
          noDataBtnLoading.value = false;
          noData.value = false;
        })
        .catch(er => {
          loading.value = false;
          noDataBtnLoading.value = false;
          noData.value = true;
          console.log(er);
        });
    };

    onMounted(fetchListData);

    // const list = [];
    // list.unshift([
    //   {
    //     money: CurrencyHelper.numberToMoney(5000000),
    //     name: Helpers.getName('Phạm Thị Thu Trang'),
    //     customer: {
    //       company: 'Thế Giới Di Động',
    //     },
    //     interest: 8,
    //     term: 3,
    //     noFee: 1,
    //     view: 1000,
    //     expired: 1,
    //     insurance: 20,
    //     expireTime: +new Date(),
    //   },
    //   {
    //     money: CurrencyHelper.numberToMoney(5000000),
    //     moneyDiscount: CurrencyHelper.numberToMoney(5000000 - 100000),
    //     name: Helpers.getName('Phạm Thị Thu Trang'),
    //     customer: {
    //       company: 'Thế Giới Di Động',
    //       starts: 3.5,
    //     },
    //     interest: 8,
    //     term: 3,
    //     view: 1000,
    //     expired: 1,
    //     expireTime: +new Date(),
    //   },
    // ]);

    return {
      list,
      listTemp: null,
      search,
      debounce: null,
      loading: false,
      noResult: false,
      noDataBtnLoading,
      noData,
      handleClickItem,
    };
  },
});
</script>
