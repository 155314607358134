
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'BaseInputSearch',
  props: {
    modelValue: {
      type: String,
    },
    id: {
      type: String,
    },
    label: {
      type: String,
    },
  },
  methods: {
    clearData() {
      this.$emit('update:modelValue', '');
    },
    handleClear() {
      this.$emit('update:modelValue', '');
      document?.getElementById('search')?.focus();
    },
    handleChange(event: any) {
      console.log('event.target.value', event.target.value);
      this.$emit('update:modelValue', event.target.value);
    },
  },
});
