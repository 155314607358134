<template>
  <span class="shadow-2 rounded-xl search p-2 flex items-center relative  ">
    <span
      class="search-icon mr-1 align-middle text-gray-500 focus:text-primary"
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        class="w-5 h-5 block"
        viewBox="0 0 20 20"
        stroke="currentColor"
      >
        <path
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-width="2"
          d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"
        />
      </svg>
    </span>
    <span class="input-wrap relative flex flex-auto items-center">
      <label
        v-if="!modelValue"
        for="search"
        class="w-full text-gray-500 focus:text-primary outline-none  bg-transparent absolute left-0 top-0"
        >Tìm kiếm
      </label>
      <input
        id="search"
        :value="modelValue"
        class="w-full expand-search outline-none bg-transparent"
        @input="handleChange"
      />
    </span>
    <span v-if="modelValue" @click="handleClear">
      <svg
        class="w-5 h-5"
        fill="currentColor"
        viewBox="0 0 20 20"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fill-rule="evenodd"
          d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
          clip-rule="evenodd"
        ></path>
      </svg>
    </span>
  </span>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'BaseInputSearch',
  props: {
    modelValue: {
      type: String,
    },
    id: {
      type: String,
    },
    label: {
      type: String,
    },
  },
  methods: {
    clearData() {
      this.$emit('update:modelValue', '');
    },
    handleClear() {
      this.$emit('update:modelValue', '');
      document?.getElementById('search')?.focus();
    },
    handleChange(event: any) {
      console.log('event.target.value', event.target.value);
      this.$emit('update:modelValue', event.target.value);
    },
  },
});
</script>
