<template>
  <div v-if="aInvest" @click="handleClick(aInvest.contractId)">
    <div class="a-salary-advance shadow-2  ">
      <div v-if="aInvest.customer?.name" class="customer-name">
        Nhân viên {{ Helper.getName(aInvest.customer.name) }}
      </div>
      <div v-if="aInvest.customer?.starts > 0" class="evaluate">
        <div class="text-warning">
          <icon-star-solid
            v-for="index in Math.floor(aInvest.customer.starts)"
            :key="`${index}-mdi-star`"
            small
            >mdi-star
          </icon-star-solid>
          <icon-start-half
            v-for="index in Math.ceil(
              aInvest.customer.starts - Math.floor(aInvest.customer.starts),
            )"
            :key="`${index}-mdi-star-half-full`"
            small
            >mdi-star-half-full
          </icon-start-half>
          <icon-start-outlined
            v-for="index in Math.floor(5 - aInvest.customer.starts)"
            :key="`${index}-mdi-star-outline`"
            small
            >mdi-star-outline
          </icon-start-outlined>
        </div>
        <span class="ml-1 relative -bottom-0.5">Đánh giá bởi Interloan</span>
      </div>
      <div class="money text-xl font-medium">
        Khoản ứng:
        <span class="text-tertiary mr-2">
          {{ HelperCurrency.numberToMoney(aInvest.amount) }}đ
        </span>
      </div>
      <div class="text-base flex justify-between mb-1">
        <div class="interest">
          Lãi suất:
          <span class="font-medium text-success">{{ aInvest.interest }}%</span>
        </div>
        <div class="time">Kỳ hạn: {{ aInvest.term }} tháng</div>
      </div>
      <div v-if="aInvest.customer" class="text-sm mb-1">
        Làm việc tại: {{ aInvest.customer.company }}
      </div>
      <div v-if="aInvest.view" class="text-sm">
        Lượt xem trong ngày: {{ aInvest.view }}
      </div>
    </div>
  </div>
</template>
<script>
import * as HelperCurrency from '@/helpers/currency';
import * as Helper from '@/helpers';
import ConstRouter from '@/constants/constRouter';
import IconStarSolid from '@/components/Icon/IconStart';
import IconStartHalf from '@/components/Icon/IconStartHalf';
import IconStartOutlined from '@/components/Icon/IconStartOutlined';

export default {
  name: 'SalaryAdvanceItem',
  components: {
    IconStartOutlined,
    IconStartHalf,
    IconStarSolid,
  },
  props: {
    aInvest: {
      type: Object,
    },
    ofUser: {
      type: Boolean,
      default: false,
    },
  },
  setup() {
    return {
      HelperCurrency,
      Helper,
    };
  },
  methods: {
    handleClick(id) {
      // check login
      // if (LocalStorage.getAuthToken()) {
      if (this.ofUser) {
        this.$router.push({
          name: ConstRouter.LENDER_SHOW.name,
          params: { id },
          query: { ofUser: true },
        });
      } else {
        // // Analytics
        // Analytics.event(
        //   'REG_LV1_LEN',
        //   'SELECT_CONTRACT',
        //   'Chọn khoản đầu tư',
        //   id,
        // );
        // Analytics.eventK2A(
        //   'REG_LV1_LEN',
        //   'SELECT_CONTRACT',
        //   'Chọn khoản đầu tư',
        //   id,
        // );
        //============================================Analytics
        localStorage.setItem('time', this.aInvest.expireTime?.toISOString());
        this.$router.push({
          name: ConstRouter.LENDER_SHOW.name,
          params: { id },
        });
      }

      // } else {
      //   this.$router.push({ name: LENDER_REQUIRED_LOGIN });
      // }
    },
  },
};
</script>

<style lang="scss" scoped>
.a-salary-advance {
  border-radius: 16px;
  padding: 16px;
  margin-bottom: 16px;
  background: white;
  position: relative;

  .customer-name {
    font-size: 24px;
    font-weight: bold;
    line-height: 1;
  }

  .evaluate {
    display: flex;
    flex-direction: row;
    align-items: center;
    font-size: 12px;

    .starts {
      display: inline-flex;
    }
  }

  @media (max-width: 365px) {
    .customer-name {
      text-align: center;
    }
    .evaluate {
      justify-content: center;
    }
    .tag-discount {
      justify-content: center;
      margin-top: 4px;

      .cash-back {
        margin-bottom: 2px;
      }
    }
    .protect {
      text-align: center;

      .protect-wrap {
        position: static !important;
      }
    }
  }
}
</style>
